import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpErrorResponse,
    HttpEvent,
    HttpContext,
    HttpEventType,
    HttpResponseBase,
    HttpStatusCode,
} from "@angular/common/http";
import { from, Observable, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SecurityService } from "./security.service";
import { ToastrService } from "ngx-toastr";
//service
import { Router } from "@angular/router";
import { baseUrl } from "../../../environments/environment";
import { StorageService } from "./storage.service";
import { ResponseModel } from "../models/response-model.model";
import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";

// declare var require;
// const Swal = require('sweetalert2');

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable({
    providedIn: "root",

})
export class DataService {
    constructor(
        private http: HttpClient,
        private securityService: SecurityService,
        private router: Router,
        private toaster: ToastrService,
        private storageservice: StorageService,
    ) {}

    get(
        url: string,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true,
        responceTye?
    ): Observable<Response> {
        return this.doGet(
            baseUrl + url,
            isAuthTokenRequired,
            params,
            isEnableLoader,
            responceTye
        );
    }

    post(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true,
        idempotencyKey?:string 
    ): Observable<Response> {
        return this.doPost(
            baseUrl + url,
            data,
            isAuthTokenRequired,
            params,
            isEnableLoader,
            idempotencyKey
        );
    }

    postWithId(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true
    ): Observable<Response> {
        return this.doPost(
            baseUrl + url,
            data,
            isAuthTokenRequired,
            params,
            isEnableLoader
        );
    }

    putWithId(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true
    ): Observable<Response> {
        return this.doPut(
            baseUrl + url,
            data,
            isAuthTokenRequired,
            params,
            isEnableLoader
        );
    }

    upload(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = false
    ): Observable<any> {
        return this.doUpload(
            baseUrl + url,
            data,
            isAuthTokenRequired,
            params,
            isEnableLoader
        );
    }

    delete(
        url: string,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = false
    ): Observable<Response> {
        return this.doDelete(
            baseUrl + url,
            isAuthTokenRequired,
            params,
            isEnableLoader
        );
    }

    patch(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
        return this.doPatch(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
    }

    private doUpload(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = false
    ): Observable<Response> {
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            isEnableLoader,
            params
        );

        return this.http.post(url, data, httpOptions).pipe(
            tap((res: Response) => {
                return res;
            }),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    private doGet(
        url: string,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true,
        responceTye?
    ): Observable<Response> {
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            false,
            null,
            null,
            responceTye
        );
        if (params != undefined) {
            // Convert the data object to query parameters
            if(Array.isArray(params)){
                let isFirstParam = true;
                let queryString = "";
                params.forEach(a=>{
                const queryParams = Object.entries(a)
                .filter(([key, value]) => value !== undefined && value !== null)
                .map(([key, value]: any) => {
                    if (isFirstParam) {
                        queryString += `?${key}=${value}`;
                        isFirstParam = false;
                    } else {
                        queryString += `&${key}=${value}`;
                    }
                })
            })
            url = url + queryString;
            }else{
                const queryParams = Object.entries(params)
                .filter(([key, value]) => value !== undefined && value !== null)
                .map(([key, value]: any) => {
                    if (
                        key === "dateRange" ||
                        key === "startTimeRange" ||
                        key === "endTimeRange"
                    ) {
                        // Handle dateRange separately
                        return Object.entries(value)
                            .map(([dateKey, dateValue]) =>
                                dateValue
                                    ? `${key}.${dateKey}=${dateValue}`
                                    : null
                            )
                            .join("&");
                    } else if (key === "pagination" || key === "sort") {
                        // Handle pagination and sort objects by stringifying them
                        if (params.pagination && params.sort) {
                            const nestedParams = Object.entries(value)
                                .map(
                                    ([nestedKey, nestedValue]) =>
                                        `${key}.${nestedKey}=${nestedValue}`
                                )
                                .join("&");
                            return nestedParams;
                        }
                    } else if (params[key]?.length){
                        if(key == 'appointmentStatusIds'){
                            const queryString = params[key]?.map(id => `${key}=${id}`).join('&');
                            return queryString;
                        }
                        }

                    return `${key}=${encodeURIComponent(value)}`;
                })
                .join("&");
                if (queryParams) {
                    url += `?${queryParams}`;
                }
            }

            // Append the query parameters to the URL

        }
        return this.http.get(url, httpOptions).pipe(
            tap((res: Response) => {
                return res;
            }),
            catchError((err) => this.handleError(err))
        );
    }

    private doPost(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true,
        idempotencyKey?:string
    ): Observable<Response> {
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            false,
            params,
            idempotencyKey
        );

        return this.http.post(url, data, httpOptions).pipe(
            tap((res: any) => {
                return res;
            }),
            catchError((err) => this.handleError(err))
        );
    }

    private doDelete(
        url: string,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true
    ): Observable<Response> {
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            false,
            params
        );

        return this.http.delete(url, httpOptions).pipe(
            tap((res: Response) => {
                return res;
            }),
            catchError((err) => this.handleError(err))
        );
    }

    private doPut(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true
    ): Observable<Response> {
        let httpOptions = {
            headers: new HttpHeaders(),
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            false,
            params
        );

        return this.http.put(url, data, httpOptions).pipe(
            tap((res: Response) => {
                return res;
            }),
            catchError((err) => this.handleError(err))
        );
    }

    private doPatch(
        url: string,
        data: any,
        isAuthTokenRequired: boolean = true,
        params?: any,
        isEnableLoader: boolean = true,
        idempotencyKey?:string
        ): Observable<Response> {

        let httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions = this.securityService.setHeaders(
            isAuthTokenRequired,
            false,
            params,
            idempotencyKey
        );

        return this.http.patch(url, data, httpOptions)
            .pipe(
                tap((res: any) => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    callRefreshTokenApiCallCount: number = 0;
    isRefreshTokenUpdate: boolean;
    private handleError(error: any) {
        if (error.status === HttpStatusCode.Unauthorized) {
            if (this.callRefreshTokenApiCallCount == 0) {
                var userDetails =
                    this.storageservice.retrieve("userAccountDetails");
                    console.log("refreshTokenObject",                    {
                        refreshToken:
                            this.storageservice.retrieve("refreshToken"),
                        organizationId:
                            userDetails?.organizations[0]?.organization?.id,
                        userId: userDetails?.id,
                    },)
                this.post(
                    "clo-api/identity/auth/refresh-token",
                    {
                        refreshToken:
                            this.storageservice.retrieve("refreshToken"),
                        organizationId:
                            userDetails?.organizations[0]?.organization?.id,
                        userId: userDetails?.id,
                    },
                    false
                ).subscribe(
                    {
                        next: (res: any) => {
                            if (res?.isSucceeded) {
                                        // this.storageservice.removeItem('authorizationDataIdToken');
                                        // this.storageservice.removeItem('refreshToken');
                                        if(res?.response?.errors?.length > 0){
                                            this.router.navigateByUrl("auth/login");
                                        }
                                        this.storageservice.store(
                                            "authorizationDataIdToken",
                                            res.response.response.accessToken
                                        );
                                        this.storageservice.store(
                                            "refreshToken",
                                            res.response.response.refreshToken
                                        );
                                        this.callRefreshTokenApiCallCount = 0;
                                        this.isRefreshTokenUpdate = res?.isSucceeded;
                                        this.reloadComponent();
                                    } else {
                                        if (res?.response?.errors?.length > 0) {
                                            this.toaster.error(res?.response?.errors[0], null, {
                                                timeOut: 700,
                                            });
                                        }
                                        this.router.navigateByUrl("auth/login");
                                        this.callRefreshTokenApiCallCount = 0;
                                    }
                        },
                        error: (err) => {
                            this.router.navigateByUrl("auth/login");
                            this.callRefreshTokenApiCallCount = 0;
                        },
                    })
                    if(!this.isRefreshTokenUpdate){
                    this.callRefreshTokenApiCallCount = this.callRefreshTokenApiCallCount + 1;
                }

            }
            // this.router.navigateByUrl('auth/login');
        } else if (error.error instanceof ErrorEvent) {
            if (error.error != undefined) {
                this.toaster.error(error.error);
            }
            if (error.error.errors.length > 0) {
                this.toaster.error(error.error.errors[0]);
            }
            return throwError(error || "server error");
        } else if (error.status === 400) {
            if (error.error.Message != undefined) {
                this.toaster.error(error.error.Message);
            }
        }
    }



    reloadComponent() {
        const currentUrl = this.router.url;
        if(currentUrl == '/dashboard'){
            this.storageservice.reloadComponent1.next(true);
        }
        else{
          if(currentUrl != '/admin-setting/form-settings'){
            // this.router.navigateByUrl('refreshcomponentOnly', { skipLocationChange: true }).then(() => {
            //   this.router.navigate([currentUrl]);
            // });
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentUrl]);
              });
          }
        }
    }
}
