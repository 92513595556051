import { PermissionService } from './../../../shared/services/permission.service';
import { StorageService } from './../../../shared/services/storage.service';
import {
    FilterModel,
    RequestStatus,
    DateRange,
    GetrequestsModel,
    AppointmentAccompanyingClientStatus,
    AppointmentInternalStatus,
    AppointmentInternalStatusString,
    spanishRequestStatus,
} from "./request-list.model";
import { Component, ViewChild } from "@angular/core";
import {
    NgbDateParserFormatter,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { CreateEditRequestComponent } from "./pop-ups/create-edit-request/create-edit-request.component";
import { RequestListService } from "./request-list.service";
import { RangeDatepickerComponent } from "src/app/shared/common-components/range-datepicker/range-datepicker.component";
import { AppointmentChangeScope, FormSettingTitle, GetLookupFormEnum, ModalTitle, Roles, SignalRGroups } from "src/app/shared/enum/common-enum";
import { Router } from "@angular/router";
import { Subscription, } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { FilterComponent } from "./pop-ups/filter/filter.component";
import { AssignInterpretersComponent } from "./pop-ups/assign-interpreters/assign-interpreters.component";
import { CommonService } from "src/app/shared/services/common.service";
import { RequestCategory, ScheduleOptions } from "./pop-ups/create-edit-request/create-edit-request.model";
import { AcceptRequestComponent } from './pop-ups/accept-request/accept-request.component';
import { DeclinedPopupComponent } from './pop-ups/declined-popup/declined-popup.component';
import { AssignModal } from './pop-ups/assign-interpreters/assign-interpreters.model';
import { FormSettingsService } from '../../AdminSettings/form-settings/form-settings.service';
import { LoadDropdownDataService } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { DropdownSections } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.model';
import { Pagination } from 'src/app/shared/models/pagination-base-model.model';
import { gender_NoPreference } from 'src/app/shared/models/common.model';
import { DatePipe } from '@angular/common';
import { DeclineRequestComponent } from '../appointments-scheduling-logs/pop-ups/decline-request/decline-request.component';
import { RealtimeService } from 'src/app/shared/services/realtime.service';
@Component({
    selector: "app-request-list",
    templateUrl: "./request-list.component.html",
    styleUrls: ["./request-list.component.scss"],
    providers:[DatePipe]
})
export class RequestListComponent extends CommonService {

    appointmentInternalStatusString = AppointmentInternalStatusString; // For string Comparison
    pageNumber = 1;
    requestStatus = RequestStatus;
    spanishRequestStatus = spanishRequestStatus;
    appointmentAccompanyingClientStatus = AppointmentAccompanyingClientStatus; // for other usersenum
    appointmentInternalStatus = AppointmentInternalStatus;  // for admin & superadmin enum
    requestStatusList = [];
    filterModel = new FilterModel();
    dateRange = new DateRange();
    getrequestsModel = new GetrequestsModel();
    requestList: any;
    totalRecords: number = 0;
    modalTitle = ModalTitle;
    @ViewChild(RangeDatepickerComponent)
    rangeDatepickerComponent: RangeDatepickerComponent;
    requestCategory = RequestCategory;
    assignModal = new AssignModal();
    roles = Roles;
    scheduleOptions = ScheduleOptions;
    shownItems = 2;
    NotAvailable = "NA";
    appointmentNumber: any;
    userDetails: any;
    saleBillSubscription2: Subscription;
    isOpenFilterSidebar: boolean = false;
    seletedlanguage: any;
    languagesListData: any;
    getLookupFormEnum = GetLookupFormEnum;
    formSettingTitle = FormSettingTitle;
    linguisticMatchCall: string;
    interpretationRequest: string;
    subscription: Subscription;
    offsetUtc;
    genderDropdownList = [];

    dropdownIds = [
        { Dsi: DropdownSections.RequestersOfOrganization },
        { Dsi: DropdownSections.Organizations },
        { Dsi: DropdownSections.Genders },
        { Dsi: DropdownSections.Services },
        { Dsi: DropdownSections.Languages },
        { Dsi: DropdownSections.AppointmentInterpreters },
        { Dsi: DropdownSections.statuses }
    ];
    selectedLanguage: any;
    createdDate: any;
    appointmentsDataUpdateSubscription : Subscription;
    constructor(
        private modalService: NgbModal,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        public requestListService: RequestListService,
        public router: Router,
        public toaster: ToastrService,
        private storageService: StorageService,
        public formSettingsService: FormSettingsService,
        public permissionService: PermissionService,
        public loadDropdownDataService: LoadDropdownDataService,
        public datePipe : DatePipe,
        public realtimeService : RealtimeService
    ) {
        super();
    }

    ngOnInit() {
        this.permissionService.permissionAfterResponse.subscribe(res => {
            if (res) {
                this.permissionService.permissionViewModel = res;
            }
        });

        this.saleBillSubscription2 =
            this.requestListService.loadRequests.subscribe((res) => {
                if (res) {
                    this.getAppontmentList();
                }
            });
        this.initRequestList();
        this.getAppontmentList();

        this.selectedLanguage = this.storageService.retrieve("selectedLanguage")
        this.userDetails = this.storageService.retrieve("userAccountDetails");
        this.offsetUtc = this.userDetails?.timezone?.match(/\(UTC([+-]\d{2}:\d{2})\)/);
        // this.getStatusColor(); 
        // this.getStatusText(); 
        this.setLanguage();
        this.subscribeToRealTimeUpdates();
    }

    subscribeToRealTimeUpdates(): void {
        this.appointmentsDataUpdateSubscription = this.realtimeService?.liveDataUpdates$.subscribe(liveData => {
            if (liveData) {
                switch (liveData.appointmentChangeScope) {
                    case AppointmentChangeScope.AppointmentCreated:
                        console.log("AppointmentCreated update received: ");
                        this.requestList.push(liveData?.response);
                        break;

                    case AppointmentChangeScope.AppointmentUpdated:
                        console.log("AppointmentUpdated update received: ");
                        let indexAppointmentUpdated = this.requestList.findIndex(item => item.id === liveData?.response?.id);
                        if (indexAppointmentUpdated !== -1)
                            this.requestList[indexAppointmentUpdated].isExpandable = this.requestList[indexAppointmentUpdated]?.isExpandable;
                        this.requestList[indexAppointmentUpdated] = liveData?.response;
                        break;

                    case AppointmentChangeScope.AppointmentStatusUpdated:
                        console.log("AppointmentStatusUpdated update received: ");
                        let indexAppointmentStatusUpdated = this.requestList.findIndex(item => item.id === liveData?.response?.id);
                        if (indexAppointmentStatusUpdated !== -1)
                            this.requestList[indexAppointmentStatusUpdated].isExpandable = this.requestList[indexAppointmentStatusUpdated]?.isExpandable;
                        this.requestList[indexAppointmentStatusUpdated].appointmentStatus = liveData?.response?.appointmentStatus;
                        break;

                    case AppointmentChangeScope.OnDemandRequestAssignmentEnabled:
                        console.log("OnDemandRequestAssignmentEnabled update received: ");
                        let indexOnDemandRequestAssignmentEnabled = this.requestList.findIndex(item => item.id === liveData?.response?.appointmentId);
                        if (indexOnDemandRequestAssignmentEnabled !== -1)
                            this.requestList[indexOnDemandRequestAssignmentEnabled].isExpandable = this.requestList[indexOnDemandRequestAssignmentEnabled]?.isExpandable;
                        this.requestList[indexOnDemandRequestAssignmentEnabled].isAssignable = true;
                        break;

                    default:
                        console.log('Unknown AppointmentChangeScope');
                        break;
                }
                this.processRequestList();
            }
            
        });
    }

    setLanguage() {
        if (this.selectedLanguage == 'en-US') {
          this.linguisticMatchCall = 'Linguistic Match Call';
          this.interpretationRequest = 'Interpretation Request';
        } else {
            this.linguisticMatchCall = 'Convocatoria De Partido Lingüístico';
            this.interpretationRequest = 'Solicitud De Interpretación';
        }
      }

    fillDropdownData(dropDownData) {
        this.loadDropdownDataService.commonGetDropdownModel.requestersOfOrganization = dropDownData?.requestersOfOrganization ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.organizations = dropDownData?.organizations ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.genders = dropDownData?.genders?? [];
        this.loadDropdownDataService.commonGetDropdownModel.services = dropDownData?.services ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.languages = dropDownData?.languages ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.appointmentInterpreters = dropDownData?.appointmentInterpreters ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.appointmentInterpreters = dropDownData?.appointmentInterpreters ?? [];
        this.loadDropdownDataService.commonGetDropdownModel.statuses = dropDownData?.statuses ?? [];
        if (this.loadDropdownDataService?.commonGetDropdownModel?.genders?.some(item => item?.id !== 0)) this.genderDropdownList = [...(this.loadDropdownDataService?.commonGetDropdownModel?.genders?? []) , gender_NoPreference];
    }

    openCreateEditRequestPopUp(Data, formType: string) {
        const datacopy = { ...Data }
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "addEditUserList",
        };
        const modalRef = this.modalService.open(
            CreateEditRequestComponent,
            modalOption
        );
        modalRef.componentInstance.sendAppointmentNo.subscribe((data) => {
            this.appointmentNumber = data;
        });
        modalRef.componentInstance.ModalTitleFromReqList =
            this.modalTitle.AppointmentForm;
        if (formType == "Edit Req") {
            modalRef.componentInstance.ModalTitleFromReqList =
                this.modalTitle.EditAppointmentForm;
            modalRef.componentInstance.editAppointmentListData = datacopy;
        }
        modalRef.result.then((res) => {
            if (res == true) {
                this.getAppontmentList();
            } else if (res == false) {
                this.getAppontmentList();
            }
        });
        // modalRef.result.then((res) => {
        //     if(this.realtimeService?.isJoinedCieloGroup(SignalRGroups.AppointmentsAll)) return;
        //     if (res == true) {
        //         this.getAppontmentList();
        //     } else if (res == false) {
        //         this.getAppontmentList();
        //     }
        // });
    }

    private formatEnumKey(key: string): string {
        return key.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    initRequestList(): void {
        this.getrequestsModel = new GetrequestsModel();
        this.getrequestsModel.pagination.pageNumber = 1;
        this.getrequestsModel.pagination.pageSize = 25;
    }

    toggle(data, event) {
        // this.shownItems=2;
        event.preventDefault();
        data.isExpandable = !data.isExpandable;
    }

    reset() {
        this.filterModel = new FilterModel();
        this.seletedlanguage = undefined;
        this.getrequestsModel = this.filterModel;
        this.rangeDatepickerComponent.onClear();
        this.getAppontmentList()
    }

    applyFilter() {
        const appliedfilter = {...this.getrequestsModel , ...this.filterModel}
        this.getrequestsModel = appliedfilter;
        this.getrequestsModel.pagination.pageNumber = 1;
        this.getAppontmentList()
    }

    getSelectedDates(event) {
        console.log(event);
        this.filterModel.dateRange.startDate = this.ngbDateParserFormatter.format(event.startDate);
        this.filterModel.dateRange.endDate = this.ngbDateParserFormatter.format(event.endDate);

        this.dateRange.dateOrDateRange.startDate =
            this.ngbDateParserFormatter.format(event.startDate);
        this.dateRange.dateOrDateRange.endDate =
            this.ngbDateParserFormatter.format(event.endDate);
    }

    filterUndefinedProperties(obj: any): any {
        const filteredObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] !== undefined)) {
                if ((obj[key] !== null)) {
                    filteredObj[key] = obj[key];
                }
            }
        }
        return filteredObj;
    }

    callInitialApiCallCount: number = 0;

    async getAppontmentList() {
        const getrequestsModel = JSON.parse(JSON.stringify(this.filterUndefinedProperties(this.getrequestsModel)));
        if(getrequestsModel.interpreterGenderRequestId == 0){
            getrequestsModel.isGenderNotPreferred = true;
            getrequestsModel.interpreterGenderRequestId = null;
        } else{
            getrequestsModel.isGenderNotPreferred = null;
            getrequestsModel.interpreterGenderRequestId = null;
        }
       
        if (this.callInitialApiCallCount == 0) {
            this.subscription = await (await this.requestListService
                .getAppointmentData(getrequestsModel))
                .subscribe((res) => {
                    this.requestList = res.response;
                    this.totalRecords = res.totalRecords;
                    this.processRequestList();
                });
        }
        this.callInitialApiCallCount = this.callInitialApiCallCount + 1;
    }

  
    processRequestList() {
        this.callInitialApiCallCount = 0;
        this.createdDate = this.requestList?.map(item => item.date)
        this.requestList?.map((x) => (x.isExpandable = false, x.itemLength = 2));
        if(this.userDetails?.organizations[0]?.role?.value==this.roles.Interpreter){
            this.requestList?.map((item)=>item.checkingRequestIsDuringOrAfter = this.checkIsEnableOrDisable(item ,this.offsetUtc))
        }
      }
      
    onPageChanged(value) {
        this.getrequestsModel.pagination.pageNumber = value;
        // this.getrequestsModel.pagination.pageSize = 10;
        this.getAppontmentList();
    }

    selectPageSize(value){
        if(value == 'All'){
            this.getrequestsModel.pagination = null;
        } else{
            if(this.getrequestsModel.pagination == null){
                const pagination =  new Pagination();
                this.getrequestsModel.pagination = pagination;
                this.getrequestsModel.pagination.pageNumber = 1;
            }
            this.getrequestsModel.pagination.pageSize = value;
        }
        this.getAppontmentList();
    }

    navigateToLog(appointmentDetails: any, navigateFrom: any = 'requestListComponent', event) {

        this.router.navigateByUrl(`appointmentsAndScheduling/logs/${appointmentDetails.id}`);
        this.requestListService.sendDataToLogs.next(navigateFrom);
    }

    openFilterForDevice() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "filterbuttondevice",
        };
        const modalRef = this.modalService.open(FilterComponent, modalOption);
        modalRef.result.then((res) => {
            if (res) {
                this.getrequestsModel = res;
                this.getAppontmentList();
            }
        });
    }

    //#region open Assign Interpreter PopUP

    openAssignInterpretersPopUp(data) {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "addEditUserList",
        };
        const modalRef = this.modalService.open(AssignInterpretersComponent, modalOption);
        modalRef.componentInstance.getRequestData = data;
        modalRef.componentInstance.reqAssigned.subscribe((res => {
            if (res == true) {
                this.getAppontmentList();
            }
        }))
    }
    //#endregion

    //#region open Accept Request PopUP
    openAcceptRequestPopUp(Data) {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "acceptpopupwidth",
        }
        const modalRef = this.modalService.open(AcceptRequestComponent, modalOption);
        modalRef.componentInstance.acceptedData = Data;
        modalRef.result.then((res) => {
            if (res == true) {
                this.getAppontmentList();
            }
        });
    }
    //#endregion

    // call declined api service
    callInitialDeclineApiCallCount: number = 0;
    getdeclined(id) {
        if (this.callInitialDeclineApiCallCount == 0) {
            if (this.userDetails?.organizations[0].role.value == Roles.Interpreter) {
                this.requestListService.declineAppointmentByInterpreter({ appointmentId: id })
                    .subscribe((res) => {
                        if (res.isSucceeded) {
                            this.getAppontmentList();
                            this.openDeclinedPopup();
                            // this.toaster.success(res.message);
                            this.callInitialDeclineApiCallCount = 0;
                        }
                        else {
                            if (res.message != undefined) {
                                this.toaster.error(res.message);
                            }
                            if (res?.errors?.length > 0) {
                                this.toaster.error(res.errors[0]);
                            }
                            this.callInitialDeclineApiCallCount = 0;
                        }
                    });
            }
            else {
                this.requestListService.getDeclined(id).subscribe((res) => {
                    if (res.isSucceeded) {
                        console.log(res);
                        this.getAppontmentList();
                        this.openDeclinedPopup();
                        //   this.toaster.success(res.message);
                        this.callInitialDeclineApiCallCount = 0;
                    }
                    else {
                        if (res.message != undefined) {
                            this.toaster.error(res.message);
                        }
                        if (res?.errors?.length > 0) {
                            this.toaster.error(res.errors[0]);
                        }
                        this.callInitialDeclineApiCallCount = 0;
                    }
                });
            }

        }
        this.callInitialDeclineApiCallCount = this.callInitialDeclineApiCallCount + 1;

    }

    // open declined popup
    openDeclinedPopup() {
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
            windowClass: "confirmedpopup",
        }
        const modalRef = this.modalService.open(DeclinedPopupComponent, modalOption);
    }

    searchList(value) {
        this.getrequestsModel.searchText = value;
        this.getAppontmentList();
    }

    openFilterSidebar() {
        this.isOpenFilterSidebar = true;
    }

    addNewLanguages(event) {
        this.filterModel.languageId = event?.languageVariant != undefined
            ? event?.languageVariant?.id
            : event?.language?.languageVariant[0].id,
            this.seletedlanguage = event;
        // this.getAppontmentList();
    }


    ngOnDestroy() {
        // this.realtimeService?.leaveCieloGroup(SignalRGroups.AppointmentsAll);
        this.appointmentsDataUpdateSubscription?.unsubscribe();
        this.subscription.unsubscribe();
    }


    convertLocalToTargetTimeZone(targetTimezoneOffset ,hours?) {
        const date = new Date();
        const utcDateTime = date.toISOString();  // Convert local date-time to UTC
       return this.convertTimes(utcDateTime , targetTimezoneOffset , hours);
      }

      convertTimes(utcDateTime , targetTimezoneOffset ,hours) : number {
        if(utcDateTime || targetTimezoneOffset ){
            const convertedTime = this.datePipe.transform(utcDateTime, 'yyyy-MM-ddTHH:mm:ss', targetTimezoneOffset);  // Adjust UTC date-time to the target timezone offset
           
            const dt =  this.convertLocalToUTC(convertedTime);
            const dtTimeInMillisecond = this.dateTimeStringToTotalMilliseconds(dt , hours);
            return dtTimeInMillisecond;
        } else {
            return 0
        }
      }

      convertLocalToUTC(localDateTimeString: string): string | null {
        return this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
      }

      dateTimeStringToTotalMilliseconds(dateTimeString: string , hours?): number {
        const dt = dateTimeString+'Z'
        const dateTime = new Date(dt);
        if(hours) dateTime.setHours(hours);
        return Date.parse(dt);
        // return dateTime.getTime();
    }

    convertLocalToUTCdateTime(localDateTimeString: string, localTimezone: string): string | null {
        const utcDateTimeString = this.datePipe.transform(localDateTimeString, 'yyyy-MM-ddTHH:mm:ss', 'UTC', localTimezone); // Transform the local date-time to UTC using DatePipe
        return utcDateTimeString;
      }

    checkAppointmentTime(data){
        if(this.userDetails?.organizations[0]?.role?.value==this.roles?.Interpreter){
            data.checkingRequestIsDuringOrAfter = this.checkIsEnableOrDisable(data,this.offsetUtc);
        }
    }

      //#region declined request confirmation poup 

  declineRequestConfirmation(id ) {
    const modalOption: NgbModalOptions = {
          centered: true,
          backdrop: "static",
          keyboard: false,
          size: "sm",
          windowClass: "unsaved-changes"};
          const modalRef = this.modalService.open(DeclineRequestComponent,modalOption);
          modalRef.result.then((res)=>{
        if (res) { 
          this.getdeclined(id)
        }else{
        }
      })
      }
  
    //#endregion declined request confirmation poup 
}