import { LoadDropdownDataService } from './../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DashboardService } from "./dashboard.service";
import { AppointementRequestCategory, DateModel, GetDashboardData, RadialChartModel } from "./dashboard.model";
import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
// import 'jvectormap/jquery-jvectormap.min.js'; // Import the jvectormap library
import 'jvectormap/tests/assets/jquery-jvectormap-us-aea-en.js';
import 'jvectormap/tests/assets/jquery-jvectormap-us-lcc-en.js';
// import 'jvectormap/lib
declare var $: any;
var $mountNode = $('[data-preview="jvectormap-next"] [data-testid="mountNode"]');

import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexMarkers,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTheme,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
} from "ng-apexcharts";
import {
    AppointmentInternalStatus,
    AppointmentInternalStatusString,
} from "../appointments-and-scheduling/request-list/request-list.model";
import { Subscription, catchError } from "rxjs";
import { CommonService } from "src/app/shared/services/common.service";
import { RequestListService } from "../appointments-and-scheduling/request-list/request-list.service";
import { FormSettingsService } from "../AdminSettings/form-settings/form-settings.service";
import { FormSettingTitle, GetLookupFormEnum, Roles } from "src/app/shared/enum/common-enum";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import * as moment from "moment";
import { NgbDateParserFormatter, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NewRangeDatePickerComponent } from "src/app/shared/common-components/new-range-date-picker/new-range-date-picker.component";
import { DropdownSections } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.model';

let primary_color = localStorage.getItem("primary_color") || "#7366ff";
let secondary_color = localStorage.getItem("secondary_color") || "#f73164";

export type ChartOptions = {
    series?: ApexAxisChartSeries;
    chart?: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    tooltip?: any;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    legend?: ApexLegend;
    labels?: string[];
    plotOptions?: ApexPlotOptions;
    fill?: ApexFill;
    responsive?: ApexResponsive[];
    pieseries?: ApexNonAxisChartSeries;
    title?: ApexTitleSubtitle;
    theme?: ApexTheme;
    colors?: string[];
    markers?: ApexMarkers;
    annotations?: ApexAnnotations;
    grid?: ApexGrid;
};

export let defaultRadialChart: ChartOptions | any = {
    chart: {
        height: 120,
        type: "radialBar",
        dropShadow: {
            enabled: false,
        },
    },
    plotOptions: {
        radialBar: {
            hollow: {
                size: "60%",
            },
            track: {
                strokeWidth: "100%",
                opacity: 1,
                background: "#E2E2E2",
                margin: 5,
            },
            dataLabels: {
                showOn: "always",
                value: {
                    color: "var(--chart-text-color)",
                    fontSize: "12px",
                    show: true,
                    offsetY: -12,
                    formatter: function (val) {
                        return val.toFixed(0); // Format the value without decimal places
                    },
                },
            },
        },
    },
    stroke: {
        lineCap: "round",
    },
    responsive: [
        {
            breakpoint: 4000,
            options: {
                chart: {
                    height: 110,
                    width: 60
                },
            },

        },
        {
            breakpoint: 1024,
            options: {
                chart: {
                    width: 75,
                },
            },
        },
        {
            breakpoint: 766,
            options: {
                chart: {
                    width: 65,
                },
            },
        },
    ],
};

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends CommonService implements OnDestroy {
    
    public data = defaultRadialChart;
    appointmentInternalStatus = AppointmentInternalStatus;
    radialChartList: RadialChartModel[] = [];
    getDashboardData = new GetDashboardData();
    getLookupFormEnum = GetLookupFormEnum;
    formSettingTitle = FormSettingTitle;
    languagesListData: any;
    selectedDates : string = ''
    seletedlanguage: any;
    copySeletedlanguage: any;
    filterDropdownList = {
        dateRange: [
            { id: 14, value: "14 Days" },
            { id: 30, value: "30 Days" },
            { id: 60, value: "60 Days" },
        ],
    }
    totalRequest: number;
    appointmentsByLocations: any[] = [];
    upcomingRequestCount: number;
    appointmentsByLanguages: any[]=[];
    totalLanguages: any;
    map: any;
    subscription: Subscription;
    appointementRequestCategory = AppointementRequestCategory
    appointmentInternalStatusString = AppointmentInternalStatusString; // For string Comparison
    roles = Roles;

    
    constructor(
        public dashboardService: DashboardService,
        public requestListService: RequestListService,
        public formSettingsService: FormSettingsService,
        public formatter: NgbDateParserFormatter,
        private modalService : NgbModal,
        public loadDropdownDataService:LoadDropdownDataService,
        public storageService : StorageService
    ) {
        super();
    }

    countryNames: any
    @ViewChild('dateRange') dateRange:any;
    //vector map -----
    title = 'new-angular';
    mapName!: string;
    seriesData: any;
    mapOptions: any;

    markersData = [
        { latLng: [32.806671, -86.79113], state: "Alabama", count: 0 },
        { latLng: [58.3019, -134.4197], state: "Alaska", count: 0 },
        { latLng: [33.729759, -111.431221], state: "Arizona", count: 0 },
        { latLng: [34.969704, -92.373123], state: "Arkansas", count: 0 },
        { latLng: [36.116203, -119.681564], state: "California", count: 0 },
        { latLng: [39.059811, -105.311104], state: "Colorado", count: 0 },
        { latLng: [41.597782, -72.755371], state: "Connecticut", count: 0 },
        { latLng: [38.896198, -77.026298], state: "Delaware", count: 0 },
        { latLng: [27.766279, -81.686783], state: "Florida", count: 0 },
        { latLng: [32.678125, -83.222976], state: "Georgia", count: 0 },
        { latLng: [20.796936, -156.331924], state: "Hawaii", count: 0 },
        { latLng: [44.240459, -114.478828], state: "Idaho", count: 0 },
        { latLng: [40.349457, -88.986137], state: "Illinois", count: 0 },
        { latLng: [39.849426, -86.258278], state: "Indiana", count: 0 },
        { latLng: [42.011539, -93.210526], state: "Iowa", count: 0 },
        { latLng: [38.5266, -96.726486], state: "Kansas", count: 0 },
        { latLng: [37.66814, -84.670067], state: "Kentucky", count: 0 },
        { latLng: [31.169546, -91.867805], state: "Louisiana", count: 0 },
        { latLng: [45.076076, -69.386171], state: "Maine", count: 0 },
        { latLng: [39.045755, -76.641271], state: "Maryland", count: 0 },
        { latLng: [42.407211, -71.382439], state: "Massachusetts", count: 0,},
        { latLng: [44.182205, -84.506836], state: "Michigan", count: 0 },
        { latLng: [46.39241, -94.63623], state: "Minnesota", count: 0 },
        { latLng: [32.741646, -89.678696], state: "Mississippi", count: 0 },
        { latLng: [38.456085, -92.288368], state: "Missouri", count: 0 },
        { latLng: [46.921925, -110.454353], state: "Montana", count: 0 },
        { latLng: [41.12537, -98.268082], state: "Nebraska", count: 0 },
        { latLng: [38.313515, -117.055374], state: "Nevada", count: 0 },
        { latLng: [43.452492, -71.563896], state: "New Hampshire", count: 0,},
        { latLng: [40.298672, -74.521973], state: "New Jersey", count: 0 },
        { latLng: [35.682839, -105.939728], state: "New Mexico", count: 0 },
        { latLng: [42.953167, -75.526672], state: "New York", count: 0 },
        { latLng: [35.492207, -79.453522], state: "North Carolina", count: 0,},
        { latLng: [47.536232, -99.90181], state: "North Dakota", count: 0 },
        { latLng: [40.388783, -82.764915], state: "Ohio", count: 0 },
        { latLng: [35.565342, -96.928917], state: "Oklahoma", count: 0 },
        { latLng: [44.572021, -122.070938], state: "Oregon", count: 0 },
        { latLng: [40.590752, -77.209755], state: "Pennsylvania", count: 0,},
        { latLng: [41.680893, -71.51178], state: "Rhode Island", count: 0 },
        { latLng: [33.856892, -80.945007], state: "South Carolina", count: 0,},
        { latLng: [44.299782, -99.438828], state: "South Dakota", count: 0,},
        { latLng: [35.747845, -86.692345], state: "Tennessee", count: 0 },
        { latLng: [31.054487, -97.563461], state: "Texas", count: 0 },
        { latLng: [39.550051, -111.546667], state: "Utah", count: 0 },
        { latLng: [44.045876, -72.710686], state: "Vermont", count: 0 },
        { latLng: [37.769337, -78.169968], state: "Virginia", count: 0 },
        { latLng: [47.400902, -121.490494], state: "Washington", count: 0 },
        { latLng: [38.491226, -80.954071], state: "West Virginia", count: 0,},
        { latLng: [44.268543, -89.616508], state: "Wisconsin", count: 0 },
        { latLng: [42.755966, -107.30249], state: "Wyoming", count: 0 },
    ];
    //vector map -----

    dropdownIds = [
        { Dsi: DropdownSections.Organizations },
    ];

    ngOnInit() {
        // this.getMetadataDropdownList();
        this.loadDashboardData();
        this.initializeMap();
    }

    fillDropdownData(dropDownData){
        this.loadDropdownDataService.commonGetDropdownModel.organizations = dropDownData?.organizations??[];
      }

    getStateName(stateCode) {
        const stateMappings = {
            'US-AL': 'Alabama',
            'US-AK': 'Alaska',
            'US-AZ': 'Arizona',
            'US-AR': 'Arkansas',
            'US-CA': 'California',
            'US-CO': 'Colorado',
            'US-CT': 'Connecticut',
            'US-DE': 'Delaware',
            'US-FL': 'Florida',
            'US-GA': 'Georgia',
            'US-HI': 'Hawaii',
            'US-ID': 'Idaho',
            'US-IL': 'Illinois',
            'US-IN': 'Indiana',
            'US-IA': 'Iowa',
            'US-KS': 'Kansas',
            'US-KY': 'Kentucky',
            'US-LA': 'Louisiana',
            'US-ME': 'Maine',
            'US-MD': 'Maryland',
            'US-MA': 'Massachusetts',
            'US-MI': 'Michigan',
            'US-MN': 'Minnesota',
            'US-MS': 'Mississippi',
            'US-MO': 'Missouri',
            'US-MT': 'Montana',
            'US-NE': 'Nebraska',
            'US-NV': 'Nevada',
            'US-NH': 'New Hampshire',
            'US-NJ': 'New Jersey',
            'US-NM': 'New Mexico',
            'US-NY': 'New York',
            'US-NC': 'North Carolina',
            'US-ND': 'North Dakota',
            'US-OH': 'Ohio',
            'US-OK': 'Oklahoma',
            'US-OR': 'Oregon',
            'US-PA': 'Pennsylvania',
            'US-RI': 'Rhode Island',
            'US-SC': 'South Carolina',
            'US-SD': 'South Dakota',
            'US-TN': 'Tennessee',
            'US-TX': 'Texas',
            'US-UT': 'Utah',
            'US-VT': 'Vermont',
            'US-VA': 'Virginia',
            'US-WA': 'Washington',
            'US-WV': 'West Virginia',
            'US-WI': 'Wisconsin',
            'US-WY': 'Wyoming',
        };
    
        return stateMappings[stateCode] || 'Unknown';
    }

    usStates = [];
    generateSeriesData(markersData) {
        this.usStates = [
            'US-AL', 'US-AK', 'US-AZ', 'US-AR', 'US-CA', 'US-CO', 'US-CT', 'US-DE', 'US-FL', 'US-GA',
            'US-HI', 'US-ID', 'US-IL', 'US-IN', 'US-IA', 'US-KS', 'US-KY', 'US-LA', 'US-ME', 'US-MD',
            'US-MA', 'US-MI', 'US-MN', 'US-MS', 'US-MO', 'US-MT', 'US-NE', 'US-NV', 'US-NH', 'US-NJ',
            'US-NM', 'US-NY', 'US-NC', 'US-ND', 'US-OH', 'US-OK', 'US-OR', 'US-PA', 'US-RI', 'US-SC',
            'US-SD', 'US-TN', 'US-TX', 'US-UT', 'US-VT', 'US-VA', 'US-WA', 'US-WV', 'US-WI', 'US-WY'
        ];
    
        const seriesData = {};
        for (const state of this.usStates) {
            var stateName = this.getStateName(state);
            var requestCount = markersData?.find(a=>a?.state == stateName)?.count;
            seriesData[state] = this.getStateColorCode(requestCount);
            seriesData[`${state}_count`] = requestCount;
        }
        return seriesData;
    }

    getRequestCount(stateCode){
        return this.requestCountListWithStates[`${stateCode}_count`];
    }

    getStateColorCode(requestCount){
        // Define your color logic based on the specified ranges
        if (requestCount === 0) {
            return '#FFFFFF'; // White for count 0
        } else if (requestCount > 0 && requestCount <= 25) {
            return '#cce4f2'; // Light blue for count 1-25
        } else if (requestCount > 25 && requestCount <= 50) {
            return '#82bdde'; // Blue for count 26-50
        } else if(requestCount > 50){
            return '#047bbc'; // Dark blue for count 51+
        }
    }

    colorCodesListWithStates = {};
    requestCountListWithStates = {};

    initializeMap() {
        this.colorCodesListWithStates = {};
        this.requestCountListWithStates = {};
        this.seriesData = this.generateSeriesData(this.markersData);
        // Iterate over the properties of this.seriesData
        for (const key in this.seriesData) {
            if (this.seriesData?.hasOwnProperty(key)) {
                if (!key.includes('_count')) {
                    this.colorCodesListWithStates[key] = this.seriesData[key];
                } else {
                    this.requestCountListWithStates[key] = this.seriesData[key];
                }
            }
        }
        // const colorScale = this.generateColorScale();
        // Assuming you have a function to initialize/update the map with the new data
        // Update this according to your actual map component implementation
        this.map = $('#us-map');
        var self = this;
        this.map.vectorMap({
            map: 'us_lcc_en', // Use the US map projection
            backgroundColor: '#FFFFFF',
            zoomOnScroll: true,
            series: {
                legend: {
                    vertical: true
                },
                regions: [
                    {
                        values: this.colorCodesListWithStates,
                        normalizeFunction: 'polynomial',
                    }
                ],
                labels: {
                    show: true, // Enable labels to display country names
                },
                markers: this.markersData,
            },
            regionStyle: {
                initial: {
                    fill: "#FFFFFF",
                    'fill-opacity': 1, // Opacity for the fill color
                    stroke: '#707070', // Change the border color here
                    'stroke-width': 1, // Width of the border
                    'stroke-opacity': 1, // Opacity of the border
                  }

            },
            onRegionOver: function (event, code) {
                $(document).ready(function() {
                    // Assuming you have a reference to the label element
                    const jVectorMapLabel = $('.jvectormap-label');
                    if(jVectorMapLabel.length > 1){
                        jVectorMapLabel.splice(0,jVectorMapLabel.length-1)
                    }
                    // Check if the element exists before modifying it
                    if (jVectorMapLabel.length) {
                        // Customize the content of the label
                        jVectorMapLabel.html(`${self.getStateName(code)}: ${self.getRequestCount(code)}`);
                
                        // Show the label if needed
                        jVectorMapLabel.show();
                    }
                });
            },
        });
      }

    addNewLanguages(event) {
        this.getDashboardData.languageVariantId = event?.languageVariant != undefined
        ? event?.languageVariant?.id
        : event?.language?.languageVariant[0]?.id,
        this.seletedlanguage = event;
        this.loadDashboardData();
}

    filterUndefinedProperties(obj: any): any {
        const filteredObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] !== undefined)) {
                if((obj[key] !== null)){
                    filteredObj[key] = obj[key];
                }
            }
        }
        return filteredObj;
    }

    //#region Radial Chart Data
    mapInitializecount = 0
    callInitialApiCallCount:number = 0;
    loadDashboardData() {
        this.getDashboardData = this.filterUndefinedProperties(this.getDashboardData);
        if(this.callInitialApiCallCount ==0){
            this.subscription =  this.dashboardService
            .getDashboardData(this.getDashboardData)
            .subscribe((res) => {
                if (res?.isSucceeded) {
                    this.callInitialApiCallCount = 0;
                        this.radialChartList = res?.response?.requestsCounts ?? [];
                        this.upcomingRequestCount = res?.response?.upcomingRequests??0;
                        this.appointmentsByLocations = res?.response?.appointmentsByLocations ?? [];
                        this.appointmentsByLanguages = res?.response?.appointmentsByLanguages ?? [];
                        this.totalRequest = (res?.response && res?.response?.requestsCounts) ?
                        res?.response?.requestsCounts?.reduce((total, item) => total + item?.count, 0) : 0;
                        this.totalLanguages = res?.response?.appointmentsByLanguages?.reduce((total, item) => total + item?.count, 0);
                        this.updatemarkersData(this.appointmentsByLocations,this.markersData);
                    // Update the seriesData
                    this.seriesData = this.generateSeriesData(this.markersData);
                        this.map?.vectorMap('get', 'mapObject').remove();
                        this.initializeMap();
                    // Initialize or update the map with the new data
                } else {
                    if (res?.message != undefined) {
                        this.toaster?.error(res?.message);
                    }
                    if (res?.errors?.length > 0) {
                        // Initialize or update the map with the new data
                        this.toaster?.error(res?.errors[0]);
                    }
                }
            });
        }
        this.callInitialApiCallCount = this.callInitialApiCallCount + 1;
        
    }

    //#endregion

    updatemarkersData(appointmentsByLocations,markersData){
        markersData?.forEach(entry => {
            entry.count = 0;
        });
        appointmentsByLocations?.forEach(appointment => {
            const stateEntry = markersData?.find(entry => entry?.state === appointment?.state);
            if (stateEntry) {
                stateEntry.count = appointment?.count;
            }
        });
        this.markersData = [...markersData];
        this.generateSeriesData(this.markersData)
    }

    openNewRangeDatePicker(){
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
          };
          var modalRef = this.modalService.open(NewRangeDatePickerComponent, modalOption);
          if(this.getDashboardData.dateRange.startDate && this.getDashboardData.dateRange.endDate){
			modalRef.componentInstance.fromDate = this.formatter?.parse(this.getDashboardData?.dateRange?.startDate);
			modalRef.componentInstance.toDate = this.formatter?.parse(this.getDashboardData?.dateRange?.endDate);
		}
          modalRef.result.then((res) => {
            if (res) {
                this.getDashboardData.dateRange = new DateModel();
                if(res?.fromDate){
                    const { year, month, day } = res?.fromDate;
                    const momentFromDate = moment([year, month - 1, day]);
                    this.getDashboardData.dateRange.startDate = moment(momentFromDate).format('YYYY-MM-DD');
                    var startDate =  moment(momentFromDate).format('MM/DD/YY');
                }
                if(res?.toDate){
                    const { year, month, day } = res?.toDate;
                    const momenttoDate = moment([year, month - 1, day]);
                    this.getDashboardData.dateRange.endDate = moment(momenttoDate).format('YYYY-MM-DD');
                    var endDate = moment(momenttoDate).format('MM/DD/YY');
                }
                this.selectedDates = startDate + ' - ' + endDate;
                this.loadDashboardData()
                
            }
        });
    }

    //#region set date range as per No. of days 
    setDateAsPerNoOfDays(noOfDays){
        if(typeof(noOfDays) == 'number'){
            this.getDashboardData.dateRange = new DateModel();
            var todayDate = moment().format('YYYY-MM-DD');
            var nextDate = moment().add(noOfDays, 'days').format('YYYY-MM-DD');
            this.getDashboardData.dateRange.startDate = todayDate;
            this.getDashboardData.dateRange.endDate = nextDate;
            this.loadDashboardData();
        }
        else if(noOfDays != undefined){
            
        }
        else{
            this.selectedDates = undefined;
            this.getDashboardData.dateRange = new DateModel();
            this.loadDashboardData();
        }
    }
    //#endregion

    //#region clear Applied Filters
    clearFilters() {
        this.getDashboardData = new GetDashboardData();
        this.seletedlanguage = undefined;
        this.dateRange.currentSelection = undefined;
        this.selectedDates = undefined;
        this.loadDashboardData();
    }
    //#endregion

    ngOnDestroy(){
        this.subscription?.unsubscribe();
    }

    disableLanguageAndClear(id , element){
        if(id == AppointementRequestCategory.LinguisticMatchCall){
            element.selectedOption = undefined;
            this.seletedlanguage=undefined;
        }
    }
}
