import { LoginService } from './../../../auth/login/login.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Component, OnInit, Input } from '@angular/core';
import { ResponseModel } from '../../models/response-model.model';
import { catchError, throwError } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;

  constructor(public loginService:LoginService,public permissionService:PermissionService) {
  }

  ngOnInit() {  
    this.permissionService.updatePermissionViewModel.subscribe(res=>{
      if(res){
        this.getUpdatePersmissionViewModel();
      }
    })
  }

  getUpdatePersmissionViewModel(){
    this.loginService.getAccessAccordingToPermission().pipe(catchError((err) => this.handleError(err)))
    .subscribe((res:ResponseModel) => {
      if (res.isSucceeded === true) {
        this.permissionService.permissionViewModel = res.response;
        this.permissionService.permissionAfterResponse.next(this.permissionService.permissionViewModel);
      }
    });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      throwError(error.error.message);
    }

    return throwError(error);
  }

}
