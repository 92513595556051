import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from "rxjs";
import { ResponseModel } from '../../../../../src/app/shared/models/response-model.model';
import { DataService } from "../../../../../src/app/shared/services/data.service";
import { CreateRequestModel } from './pop-ups/create-edit-request/create-edit-request.model';
import { GetrequestsModel } from './request-list.model';
import { AssignModal } from './pop-ups/assign-interpreters/assign-interpreters.model';

@Injectable({
  providedIn: 'root'
})
export class RequestListService {
  
  public sendDataToLogs = new BehaviorSubject(undefined);
  public loadRequests = new Subject<boolean>();
  deleteIconClicked$ = this.loadRequests.asObservable();

  constructor(public service: DataService) { }
  
  triggerLoadReq() {
    this.loadRequests.next(true);
  }

  InsertAppointment(data:CreateRequestModel ,idemPotencyKey):Observable<ResponseModel>{
  let url = "clo-api/cielo/appointment";
  const json = JSON.stringify(data);
  console.log(json);
    return this.service.post(url, data, true ,null ,true , idemPotencyKey).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
 } 

 UpdateAppointment(data:CreateRequestModel):Observable<ResponseModel>{
  let url = "clo-api/cielo/appointment";
  const json = JSON.stringify(data);
  console.log(json);
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
 } 

//  getAppointmentData(data:GetrequestsModel):Observable<ResponseModel>{
//   let url = "clo-api/cielo/appointment/list";
//   const json = JSON.stringify(data);
//   console.log(json);
//     return this.service.post(url, data, true).pipe<ResponseModel>(
//       tap((response: any) => {
//         return response;
//       })
//     );
//   }


  async getAppointmentData(data:GetrequestsModel):Promise<Observable<ResponseModel>>{
    let url = "clo-api/cielo/appointment";
      return await this.service.get(url,true,data).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  getDeclined(id:number): Observable<ResponseModel> {
    // let url =`clo-api/cielo/appointment/internal/cancel?AppointmentId=${id}`;
    let url =`clo-api/cielo/appointment/decline?AppointmentId=${id}`;
    return this.service.putWithId(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAccepted(id:number): Observable<ResponseModel> {
    let url =`clo-api/cielo/appointment/accept?AppointmentId=${id}`;
    return this.service.putWithId(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  InternalAssign(data:AssignModal): Observable<ResponseModel> {
    const json = JSON.stringify(data);
  console.log(json);
    // let url ="clo-api/cielo/appointment/internal/assign";
    let url ="clo-api/cielo/appointment/assign";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //#region for interpreter accept,decline
  acceptAppointmentByInterpreter(data):Observable<ResponseModel>{
    // let url = "clo-api/cielo/appointment/interpreter/accept";
    let url = "clo-api/cielo/assignment/accept";
    const json = JSON.stringify(data);
    console.log(json);
      return this.service.putWithId(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
  }

  declineAppointmentByInterpreter(data):Observable<ResponseModel>{
    // let url = "clo-api/cielo/appointment/interpreter/decline";
    let url = "clo-api/cielo/assignment/decline";
    const json = JSON.stringify(data);
    console.log(json);
      return this.service.putWithId(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
   } 
   completeAppointmentByInterpreter(data):Observable<ResponseModel>{
    // let url = "clo-api/cielo/appointment/interpreter/decline";
    let url = "clo-api/cielo/assignment/complete";
    const json = JSON.stringify(data);
    console.log(json);
      return this.service.putWithId(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
   } 
  //#endregion

}
