import { StorageService } from './../../../shared/services/storage.service';
import { GetUserDataModel, UserList, DropDown } from './tabs-component/user-list/user-list.model';
import { Injectable } from "@angular/core";
import { Observable, Subject, tap } from "rxjs";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import { DataService } from "src/app/shared/services/data.service";
import {
  GetRoleDataModel,
  UserRoles,

} from "./tabs-component/user-roles/user-roles.model";
import { Router } from '@angular/router';
import { TabView } from './user-management.model';
import { Roles } from 'src/app/shared/enum/common-enum';
@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  constructor(public service: DataService,private storageService:StorageService , public router : Router) {}
  updateMyAccountInfo = new Subject();
  //#region for User List

  getAllUserList(data: GetUserDataModel): Observable<ResponseModel> {
    let url = "clo-api/identity/users";
    return this.service.get(url,true,data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllClientUserList(data: GetUserDataModel): Observable<ResponseModel> {
    let url = "clo-api/identity/users/client";
    return this.service.get(url,true,data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllUserDataForLoginUser(): Observable<ResponseModel>{
    let url = "clo-api/identity/users/me";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  createNewUser(data: UserList , tabId): Observable<ResponseModel> {
    let url1 = "clo-api/identity/users";
    let url2 = "clo-api/identity/users/client";
    let url = tabId == TabView.CLIENTUSERLIST ? url2 : url1;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateUser(data: UserList , tabId?): Observable<ResponseModel> {
    let isLoggedUser = this.storageService.retrieve('userAccountDetails')?.id == data?.id;
    let isLoginUser = (this.storageService.retrieve('userAccountDetails')?.id == data?.id && this.router.url?.includes('/loginuser'))
    let url1= "clo-api/identity/users/me";
    let url2 = "clo-api/identity/users";
    let url3 = "clo-api/identity/users/client";
    const url = isLoginUser? url1 : tabId == TabView.CLIENTUSERLIST ? url3 : url2;
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        if(isLoggedUser){
          this.getAllUserDataForLoginUser().subscribe((res)=>{
            if(res?.isSucceeded){
              const userDetails = this.storageService.retrieve('userAccountDetails');
              const updatedDetails = res?.response[0];
              userDetails.firstName =updatedDetails.firstName;
              userDetails.lastName =updatedDetails.lastName
              userDetails.timezone =updatedDetails.timeZone?.value;
              this.storageService.store('userAccountDetails' ,userDetails);
              this.updateMyAccountInfo.next(true);
            }
          })
        }
        return response;
      })
    );
  }

  deleteUser(data: string): Observable<ResponseModel> {
    let url = `clo-api/identity/users?id=${data}`;
    return this.service.delete(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //#endregion

  //#region roles page api's

  getAllRoles(data: GetRoleDataModel): Observable<ResponseModel> {
    let url = "clo-api/identity/roles";
    return this.service.get(url,true, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  createNewRole(data: any): Observable<ResponseModel> {
    let url = "clo-api/identity/roles";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateRole(data: UserRoles): Observable<ResponseModel> {
    let url = "clo-api/identity/roles";
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getGenderDropDown(): Observable<ResponseModel> {
    let url = "clo-api/cielo/dropdown";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllUsersByOrgAndGroupId(organizationId,groupId): Observable<ResponseModel> {
   let url = `clo-api/cielo/dropdown/organization/users_basic/is_in_group_flag?OrganizationId=${organizationId}&GroupId=${groupId}`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
  }

  //#region user activation and deactivation code

  requestActivation(data: any ,tabId): Observable<ResponseModel> {
    let url1 = "clo-api/identity/users/request-activation";
    let url2 = "clo-api/identity/users/client/request-activation";
    let url = tabId ==  TabView.CLIENTUSERLIST ? url2 : url1 ;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  confirmActivationDeactivation(data: any,tabId): Observable<ResponseModel> {
    let url1 = "clo-api/identity/users/confirm-activation";
    let url2 = "clo-api/identity/users/client/confirm-activation";
    let url = tabId ==  TabView.CLIENTUSERLIST ? url2 : url1 ;
    return this.service.patch(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //#endregion user activation and deactivation code

  //#endregion
}