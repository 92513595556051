import { Renderer2 } from '@angular/core';
import { DropDownModel } from './../../../components/AdminSettings/user-management/tabs-component/user-list/user-list.model';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { CommonDropDownModel } from '../load-dropdown-data/load-dropdown-data.model';
import { InputComponent } from '../input/input.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class DropdownComponent{

  typeInText:string;
  originalDropDownList:CommonDropDownModel[] = [];

  @Input() isRequired:boolean;
  @Input() id?:string;
  @Input() isCountryCodeDropdown?: boolean = false
  @Input() isDropdownDisabled?:boolean = false;
  @Input() dropDownLabel:string;
  @Input() dropdownValue:number|string;
  @Input() dropDownList:CommonDropDownModel[];
  @Output() dropdownValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() dropdownValueEmitForName:EventEmitter<string> = new EventEmitter();
  @Output() dropdownDataEmit : EventEmitter<any> = new EventEmitter();
  @Output() dropdownValueOpenRangeDatePicker:EventEmitter<string> = new EventEmitter();
  @Output() contactNumberMaxLengthEmit:EventEmitter<string> = new EventEmitter();
  @Output() clearDropdownValueErrer:EventEmitter<string> = new EventEmitter();
  @Input() dynamic_class:string;
  @Input() dynamic_heightClass?:string;
  clicked = false;
  active  = false;
  option = new CommonDropDownModel();
  @Input() customRange:boolean=false;
  @Input() smallInput:boolean;
  @Input() inputLabel:string;
  @Input() showPlaceholderText?:string;
  @Input() isTooltipVisible?:string;
  @Input() toolTipText?:string;
  @Input () optionDisable:number=null;
  @Input () currentSelectionDate  :string;
  @Input () isCountryCode  :boolean = false;
  @Input () isCountryCodeAndNumerMessage : boolean; 
  @Input () isDiscription : boolean = false;
  @ViewChild(InputComponent) inputComponent: InputComponent;
  @ViewChild('dropdown') dropdown: NgbDropdown;


    selectedLanguage: any;
  dropdownInputElement:any
  constructor() {
    this.disableAutocomplete();
   }

  ngOnInit(): void {
  }

  onBlur(event: FocusEvent): void {
    this.dropdownInputElement = event.target as HTMLInputElement;

  }

  filterDropdownList(){
    this.dropDownList = this.originalDropDownList?.filter(option=>option?.value?.toLowerCase()?.includes((this.typeInText??'')?.toLowerCase()));
    this.disableAutocomplete();
  }

  disableAutocomplete() {
    const formFields = document.getElementsByTagName('input');
    for (let i = 0; i < formFields.length; i++) {
      formFields[i].setAttribute('autocomplete', 'off');
    }
  }

  clearInput(event:Event) {
    this.clearDropdownValueErrer.emit(this.currentSelection);
    this.currentSelection={};
    event.stopPropagation();
    this.setCurrentSelection(this.currentSelection)
  }

  setCurrentSelection(option) {
    this.currentSelection = option;
    this.dropdownValueEmit.emit(option.id);
    this.dropdownValueEmitForName.emit(option.value);
    this.dropdownDataEmit.emit(option);
    this.dropDownList = this.originalDropDownList;
    // console.log( this.dropDownList);
  }

  setValue(val){
      const value = val && val?.split(' - ')[1];
      return value
  }

  currentSelection:any = 
    {
    value:'',
    id:0
    }
  ;


  onClick() {
    this.clicked = !this.clicked;
}

ngOnChanges(){
  this.optionDisable;
  if(this.dropDownList != undefined){
    this.originalDropDownList = [...this.dropDownList];
      this.option.id = this.dropdownValue;
      this.option.value = this.dropDownList?.find(a=>a.id == this.dropdownValue)?.value;
      this.option.organizationId = this.dropDownList?.find(a=>a.id == this.dropdownValue)?.organizationId;
      this.option.contactNumberMaxLength = this.dropDownList?.find(a=>a?.id == this.dropdownValue)?.contactNumberMaxLength;
      this.option.contactNumberMinLength = this.dropDownList?.find(a=>a?.id == this.dropdownValue)?.contactNumberMinLength;
    this.setCurrentSelection(this.option);
  }
}

openCustomDateRangePicker(){
  this.dropdownValueOpenRangeDatePicker.emit();
}

CalculateHeight() : string {
  if(this.dropDownList?.length > 4){
    return '8.35rem';
  }
  else{
    return 'fit-content';
  }
}

@ViewChild('content') contentElement: ElementRef;

  isTextOverflowing: boolean = false;

  checkTextOverflow(text: string): void {
    const element = document.createElement('span');
    element.textContent = text;
    element.style.visibility = 'hidden';
    element.style.position = 'absolute';
    element.style.whiteSpace = 'nowrap';
    // Get the font family of the container element
    const fontFamily = window.getComputedStyle(this.contentElement.nativeElement).fontFamily;
    const fontSize = window.getComputedStyle(this.contentElement.nativeElement).fontSize;
    const letterSpacing = window.getComputedStyle(this.contentElement.nativeElement).letterSpacing;
    element.style.fontFamily = fontFamily;
    element.style.fontSize = fontSize;
    element.style.letterSpacing = letterSpacing;

    document.body.appendChild(element);
    this.isTextOverflowing = element.offsetWidth+0.5 > this.contentElement?.nativeElement?.offsetWidth;
    document.body.removeChild(element);
  }


  dropdownOpenClose(value){
    if(value){
      this.typeInText = '';
      this.filterDropdownList();
    }
  }

}
