import { StorageService } from "src/app/shared/services/storage.service";
import {
    Component,
    PLATFORM_ID,
    Inject,
    ViewChild,
    ElementRef,
} from "@angular/core";
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
// import { TranslateService } from '@ngx-translate/core';
import { environment } from "../environments/environment";
import { getMessaging, getToken } from "firebase/messaging";
import { BehaviorSubject } from "rxjs";
// import { ActivityMonitorService } from './shared/services/activity-monitor.service';
import { LoginService } from "./auth/login/login.service";
import { ResponseModel } from "./shared/models/response-model.model";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IdleDetectionService } from "./shared/services/idle-detection.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {

    @ViewChild("message") elementRef: ElementRef;
    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map((v) => v[1])
    );

    title = "af-notification";
    message: any = null;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loader: LoadingBarService,
        public storageService: StorageService,
        // private activityMonitorService: ActivityMonitorService,
        public loginService:LoginService,
        public router: Router,
        public toaster:ToastrService,
        private idleDetectionService: IdleDetectionService
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.requestPermission();
        }, 3000);
        // this.activityMonitorService.inactivityDetected.subscribe(() => {

        //     this.loginService.SignOut({refreshToken:this.storageService.retrieve("refreshToken")}).subscribe((res:ResponseModel)=>{
        //         if(res.isSucceeded){
        //         //   this.storageService.clear();
        //           this.router.navigateByUrl('auth/login');
        //         }
        //         else{
        //           if (res.message != undefined) {
        //             this.toaster.error(res.message);
        //         }
        //         if (res?.errors?.length > 0) {
        //             this.toaster.error(res.errors[0]);
        //         }
        //         }
        //       });
        // });
    }

    requestPermission() {
        return new Promise((resolve) => {
            if (!("Notification" in window)) {
                console.log("Notifications are not supported in this browser.");
                resolve("denied");
            } else {
                Notification.requestPermission().then((permission) => {
                    if (permission == "granted") {
                        const messaging = getMessaging();
                        // Register the service worker before calling getToken
                        navigator.serviceWorker
                            .register("/firebase-messaging-sw.js")
                            .then((registration) => {
                                getToken(messaging, {
                                    vapidKey: environment.firebase.vapidKey,
                                    serviceWorkerRegistration: registration,
                                })
                                    .then((currentToken) => {
                                        if (currentToken) {
                                            this.storageService.currentDeviceToken.next(currentToken);
                                            this.storageService.store(
                                                "notificationDeviceToken",
                                                currentToken
                                            );
                                                        
                                        } else {
                                            console.log(
                                                "No registration token available. Request permission to generate one."
                                            );
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(
                                            "An error occurred while retrieving token. ",
                                            err
                                        );
                                    });
                            })
                            .catch((err) => {
                                console.error(
                                    "Error registering the service worker: ",
                                    err
                                );
                            });
                    }
                    // else if (permission === 'denied') {
                    //     alert('Notification permission required,Allow Notification from site settings');
                    //     // Do something when permission is denied.
                    // } else {
                    //     alert('Notification permission required,Allow Notification from site settings');
                    //     // Do something when permission is dismissed or not available.
                    // }
                });
            }
        });
    }
}
