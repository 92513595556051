// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBosyitZIJyly2sko0Bwnzkeq2Vve3TY6I",
        authDomain: "cielo-fa891.firebaseapp.com",
        projectId: "cielo-fa891",
        storageBucket: "cielo-fa891.appspot.com",
        messagingSenderId: "63549732409",
        appId: "1:63549732409:web:4463275e57ae2021717fe8",
        measurementId: "G-CDQV41DK4Y",
        vapidKey: "BIZ8M1YDHa9hVszr7d4zfTtT5LcBjeJCh-QqVO8IiiJjJVF1b6Et_KHum19veIRQJZYgAAg0Z81lSRUxBcOWq1A"
    },
};


export const baseUrl = "https://cieloapi.mycielo.net/";
// export const baseUrlSR =  "https://cieloappapi.12skiestech.com/";
export const baseUrlCieloSR =  "wss://appapi.mycielo.net/";
export const baseUrlIdentitySR =  "wss://identityapi.mycielo.net/";

// export const baseUrl = "https://localhost:7010/";
// // export const baseUrlSR =  "https://localhost:7010/";
// export const baseUrlCieloSR =  "wss://localhost:7246/";
// export const baseUrlIdentitySR =  "wss://localhost:7099/";

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
