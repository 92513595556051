import { StorageService } from './../../services/storage.service';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { LoadDropdownDataService } from '../load-dropdown-data/load-dropdown-data.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputComponent implements OnChanges{

  @Input() form:NgForm|null;
  @Input() id?:string;
  @Input() isCountryCodeDropdown?:boolean=false;
  @Input() name?:string;
  @Input() inputType:string='text';
  @Input() inputPlaceholder:string='';
  @Input() inputLabel:string;
  @Input() inputValue:string='';
  @Input() isRequired:boolean;
  @Input() maxlength?:number;
  @Input() minlength?:number;
  @Input() smallInput:boolean;
  @Input() formSettingInput:boolean;
  @Input() inputLengthMessage?:boolean;
  // @Input() isLastNameMessage?:boolean;
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() onKeyUp:EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() focus:EventEmitter<KeyboardEvent> = new EventEmitter();
  @Input() show:boolean;
  @Input() inputDisabled?:boolean;
  @Input() inputClass?:string;
  @Input() pattern?:string;
  @Input() isReadOnly?:string;
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() isCountryCodeAndNumerMessage?:boolean=false;
  @Input() isCountryCodeAndNumerInput?:string;
  @Input() dropdownValue:number|string;
  @Output() dropdownValueEmit:EventEmitter<string> = new EventEmitter();
  showMessage:boolean=false;
  @Output() errorMessage:EventEmitter<boolean> = new EventEmitter();
  @Input() currentSelection : string
  //time 
  @Input() minTime: string = '';
  @Input() maxTime: string = '';
  
  contactNumberMaxLength 
  contactNumberMinLength 
  inputValueCount: any;


        
    constructor(public loadDropdownDataService: LoadDropdownDataService, public storageService : StorageService, private cdr: ChangeDetectorRef) { 
    this.disableAutocomplete();
  }
 ngOnChanges() {
  // this.showMessage = true;
 }

  ngOnInit(): void {
  }

  onKeyPress(event: KeyboardEvent): void {
    if (this.isCountryCodeDropdown) {
      this.disableKeys(event);
    }
  }

  disableAutocomplete() {
    const formFields = document.getElementsByTagName('input');
    for (let i = 0; i < formFields.length; i++) {
      formFields[i].setAttribute('autocomplete', 'off');
    }
  }
  
  ngAfterViewInit(): void {
    this.showMessage = false;
    this.cdr.detectChanges();  // Ensure change detection runs after updating showMessage
    document.getElementById('hidden_'+this.name)?.focus()
  }
 

  disableKeys(e) {
    document.all ? e.keyCode : e.keyCode;
    return (e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57));
  }

  getValue(e){
    this.focus.emit(e);
  }

}