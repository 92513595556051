<div class="container-fluid p-4">
    <div class="row px-3">
        <div class="col-12 mb-4 px-0">
            <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
                <p class="f-13 color-red font-semi-bold mb-0">{{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTDECLINE" | translate}}</p>
            </div>
            <div>
                <p *ngIf="userDetails?.organizations[0]?.role?.value == roles?.Interpreter" class="f-13 color-primary-grey font-semi-bold mb-2 text-center">
                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTCANCELLEDMESSAGE" | translate}} 
                </p>
                <p *ngIf="userDetails?.organizations[0]?.role?.value !== roles?.Interpreter" class="f-13 color-primary-grey font-semi-bold mb-2 text-center">
                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ADMIN.LINE_ONE.REQUESTCANCELLEDMESSAGE" | translate}} <br>
                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ADMIN.LINE_TWO.REQUESTCANCELLEDMESSAGE" | translate}} 
                </p>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-4 py-1">
            <p class="f-13 color-red font-semi-bold mb-1 cursor-pointer" (click)="activeModal.close()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate }}</p>      
        </div> 
    </div>
</div>



<!-- <div class="container-fluid p-4">
    <div class="row px-5">
        <div class="col-12 mb-4 px-0">
            <div class="d-flex align-items-center justify-content-center mt-2 mb-4">
                <p class="f-13 color-red font-semi-bold mb-0">{{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTCANCELLED" | translate}}</p>
            </div>
            <div class="col-12 form-group mb-2">
                <form>
                   <div class="col-12">
                        <app-dropdown
                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.PLACEHOLDER.SELECTREASON' | translate"
                            [dropdownValue]="" (dropdownValueEmit)="$event"
                            [dropDownList]="" [isRequired]="true"
                            [id]="'resons_id'">
                        </app-dropdown>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-4 py-1">
            <p class="f-13 color-red font-semi-bold mb-1 cursor-pointer" (click)="activeModal.dismiss()">{{"COMMON.BUTTON.BUTTONLABEL.CLOSE" | translate }}</p>      
        </div> 
    </div>
</div> -->