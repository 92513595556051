export class Dashboard {
}

export class DateModel {
    startDate: string;
    endDate: string;
}

export class GetDashboardData
{
    appointmentCategoryId?: number;
    languageVariantId?: number;
    organizationId?: number;
    communicationTypeId?: number;
    dateRange = new DateModel();
    requestsHappensInNextDays?:number;
    isRequestLanguages?:boolean = false;
}

export class RadialChartModel
{
    label:string = "NA";
    count:number = 0;
    colorHexCode:string;
}

export enum AppointementRequestCategory {
    InterprterRequest = 1,
    LinguisticMatchCall = 2
}
