import { Injectable } from '@angular/core';
import { baseUrlCieloSR, baseUrlIdentitySR } from '../../../environments/environment';
import { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { StorageService } from './storage.service';
import { HubConstant } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})

export class RealtimeService {
  string: [];
  roleName
  private hubConnectionForCielo?: HubConnection;
  private hubConnectionForIdentity?: HubConnection;

  constructor(private storageService: StorageService) { }
  private liveDataUpdates = new Subject<any>();
  public liveDataUpdates$ = this.liveDataUpdates.asObservable();

  private deactivateUser = new Subject<any>();
  public deactivateUser$ = this.deactivateUser.asObservable();

  private permissionChanged = new Subject<any>();
  public permissionChanged$ = this.permissionChanged.asObservable();

  private groupJoinStatus: Map<string, boolean> = new Map();

  // # region HubConnectionForCielo
  async createHubConnectionForCilo() {
    this.hubConnectionForCielo = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrlCieloSR}clo-hub/cielo`, {
        skipNegotiation: true,
        // headers: { 'Authorization': `Bearer ${accessToken}` },
        accessTokenFactory: () => {
          // Return the access token as a string
          return this.storageService.retrieve("authorizationDataIdToken") || '';
        },
        transport: signalR.HttpTransportType.WebSockets
      }).withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information) // Add logging for better debugging
      .build();

    this.setupCieloConnectionHandlers();
    await this.startConnectionForCielo();

    this.hubConnectionForCielo?.on(HubConstant.DashboardUpdated, (data) => {
      // Handle the appointment update data
      console.log("Dashboard update received: ", data);
      this.liveDataUpdates.next(data);
    });

    this.hubConnectionForCielo?.on(HubConstant.AppointmentUpdated, (data) => {
      // Handle the appointment update data
      console.log("Appointment update received: ", data);
      this.liveDataUpdates.next(data);
    });
  }

  private setupCieloConnectionHandlers() {
    this.hubConnectionForCielo?.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`, error);
    });

    this.hubConnectionForCielo?.onreconnected((connectionId) => {
      console.log(`Reconnected. Connection ID: ${connectionId}`);
    });

    this.hubConnectionForCielo?.onclose((error) => {
      console.error('Connection closed:', error);
      // setTimeout(() => this.startConnection(), 5000); // Attempt to reconnect after 5 seconds
    });
  }

  private async startConnectionForCielo() {
    if (this.hubConnectionForCielo.state === signalR.HubConnectionState.Disconnected) {
      await this.hubConnectionForCielo?.start()
        .then(() => console.log('Connection started'))
        .catch(err => {
          console.error('Error while starting connection: ' + err);
          // setTimeout(() => this.startConnection(), 5000); // Retry after a delay if failed
        });
    } else if (this.hubConnectionForCielo.state === signalR.HubConnectionState.Connected) {
      console.log('Already connected, no need to start again');
    } else {
      console.log('Connection is already in progress or connected');
    }
  }

  public disposeCieloConnection() {
    if (this.hubConnectionForCielo && this.hubConnectionForCielo?.state !== signalR.HubConnectionState.Disconnected) {
      this.hubConnectionForCielo?.stop()?.then((res) => console.log("The connection has been stopped", res))?.catch(err => console.error('Error while stopping connection:', err));
    }
  }

  // public joinCieloGroup(groupName: string): void {
  //   if (this.hubConnectionForCielo && this.hubConnectionForCielo?.state !== signalR.HubConnectionState.Disconnected) {
  //     this.hubConnectionForCielo?.invoke('JoinGroup', groupName)?.then((res) => {
  //       console.log('the group has been joined: ', res);
  //       this.groupJoinStatus?.set(groupName, true);
  //     })?.catch(err => {
  //       console.error('Error joining group: ', err)
  //       this.groupJoinStatus?.set(groupName, false);
  //     });
  //   }
  // }

  public isJoinedCieloGroup(groupName: string): boolean {
    return this.groupJoinStatus?.get(groupName) ?? false;
  }

  // public leaveCieloGroup(groupName: string): void {
  //   console.log(this.roleName, 'rolename')
  //   if (this.hubConnectionForCielo && this.hubConnectionForCielo?.state !== signalR.HubConnectionState.Disconnected) {
  //     this.hubConnectionForCielo?.invoke('LeaveGroup', groupName)?.then((res) => console.log('the group has been leaved', res))?.catch(err => console.error('Error leaving group: ', err));
  //     this.groupJoinStatus?.set(groupName, false);
  //   }
  // }
  //#endregion


  // # region HubConnectionForIdentity
  async createHubConnectionForIdentity() {
    this.hubConnectionForIdentity = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrlIdentitySR}clo-hub/identity`, {
        skipNegotiation: true,
        // headers: { 'Authorization': `Bearer ${accessToken}` },
        accessTokenFactory: () => {
          // Return the access token as a string
          return this.storageService.retrieve("authorizationDataIdToken") || '';
        },
        transport: signalR.HttpTransportType.WebSockets
      }).withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information) // Add logging for better debugging
      .build();

    this.setupIdentityConnectionHandlers();
    await this.startConnectionForIdentity();

    this.hubConnectionForIdentity?.on(HubConstant.Logout, (data, ) => {
      console.log("Logout update received: ", data);
      this.deactivateUser.next(true);
    });

    this.hubConnectionForIdentity?.on(HubConstant.PermissionsUpdated, (data) => {
      console.log("Permissions update received: ", data);
      this.permissionChanged.next(data);
    });
  }

  private setupIdentityConnectionHandlers() {
    this.hubConnectionForIdentity?.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`, error);
    });

    this.hubConnectionForIdentity?.onreconnected((connectionId) => {
      console.log(`Reconnected. Connection ID: ${connectionId}`);
    });

    this.hubConnectionForIdentity?.onclose((error) => {
      console.error('Connection closed:', error);
      // setTimeout(() => this.startConnection(), 5000); // Attempt to reconnect after 5 seconds
    });
  }

  private async startConnectionForIdentity() {
    if (this.hubConnectionForIdentity.state === signalR.HubConnectionState.Disconnected) {
      await this.hubConnectionForIdentity?.start()
        .then(() => console.log('Connection started'))
        .catch(err => {
          console.error('Error while starting connection: ' + err);
          // setTimeout(() => this.startConnection(), 5000); // Retry after a delay if failed
        });
    } else if (this.hubConnectionForIdentity.state === signalR.HubConnectionState.Connected) {
      console.log('Already connected, no need to start again');
    } else {
      console.log('Connection is already in progress or connected');
    }
  }

  public disposeIdentityConnection() {
    if (this.hubConnectionForIdentity && this.hubConnectionForIdentity?.state !== signalR.HubConnectionState.Disconnected) {
      this.hubConnectionForIdentity?.stop()?.then((res) => console.log("The connection has been stopped", res))?.catch(err => console.error('Error while stopping connection:', err));
    }
  }

  public isJoinedIdentityGroup(groupName: string): boolean {
    return this.groupJoinStatus?.get(groupName) ?? false;
  }

  //#endregion

}