import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() id?:string;
  @Input() name?:string;
  @Input() buttonType:string;
  @Input() buttonClass:string;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() inputReadonly : boolean  = false;
  @Input() buttonLabel:string;
  @Input() isCrossIcon:boolean=false;
  @Input() isClearIcon:boolean=false;

  @Output("crossButtonOutputEvent") crossButtonOutputEvent: EventEmitter<any> = new EventEmitter();


  
  constructor() { }

  ngOnInit(): void {
  }
}
