<div class="media profile-media">

  <!-- <svg class="user-profile" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
    <path id="Union_1" data-name="Union 1" d="M0,23A23,23,0,1,1,23,46,23,23,0,0,1,0,23ZM14.827,42.35A20.985,20.985,0,0,0,35.611,39.8a12.2,12.2,0,0,0-24.346.625,20.933,20.933,0,0,0,3.562,1.925Zm0-38.7A20.994,20.994,0,0,0,8.15,37.85q.482.482.99.929a14.491,14.491,0,0,1,9.587-11.637,8.357,8.357,0,1,1,9.457,0,14.492,14.492,0,0,1,9.465,10.906l.2-.2A21.006,21.006,0,0,0,14.827,3.65Zm2.531,16.6a6.1,6.1,0,1,0,6.1-6.1,6.1,6.1,0,0,0-6.1,6.1Z" fill="#047bbc"/>
  </svg> -->
  
  
  <div class="media-body m-l-5"><span class="text-wrap text-truncate">{{userName | translate}}</span>
    <p class="mb-0">{{role | translate}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li class="d-flex align-items-center" (click)="navigate()">
    <svg style="stroke: none;" xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
    <g id="user_1_" data-name="user (1)" transform="translate(0 0)">
      <path id="Path_35131" data-name="Path 35131" d="M92,9.648A4.886,4.886,0,0,0,95.52,8.235a4.6,4.6,0,0,0,1.457-3.411A4.6,4.6,0,0,0,95.52,1.413a5.08,5.08,0,0,0-7.031,0,4.6,4.6,0,0,0-1.457,3.411,4.6,4.6,0,0,0,1.457,3.411A4.888,4.888,0,0,0,92,9.648Zm0,0" transform="translate(-83.63 0)" fill="#727272"/>
      <path id="Path_35132" data-name="Path 35132" d="M16.959,253.043a11.722,11.722,0,0,0-.165-1.266,9.83,9.83,0,0,0-.317-1.272,6.231,6.231,0,0,0-.532-1.187,4.488,4.488,0,0,0-.8-1.028,3.547,3.547,0,0,0-1.153-.712,4.045,4.045,0,0,0-1.472-.262,1.508,1.508,0,0,0-.8.332c-.239.153-.519.331-.831.527a4.8,4.8,0,0,1-1.075.466,4.244,4.244,0,0,1-2.63,0,4.792,4.792,0,0,1-1.074-.465c-.309-.194-.589-.371-.832-.527a1.506,1.506,0,0,0-.8-.332,4.04,4.04,0,0,0-1.471.262,3.544,3.544,0,0,0-1.153.712,4.489,4.489,0,0,0-.8,1.028A6.243,6.243,0,0,0,.522,250.5a9.854,9.854,0,0,0-.317,1.272,11.68,11.68,0,0,0-.165,1.266c-.027.383-.041.781-.041,1.183a3.3,3.3,0,0,0,1,2.516,3.654,3.654,0,0,0,2.589.928h9.811a3.653,3.653,0,0,0,2.589-.928A3.3,3.3,0,0,0,17,254.226c0-.4-.014-.8-.041-1.183Zm0,0" transform="translate(0 -237.67)" fill="#727272"/>
    </g>
  </svg>
  <span>{{'USERSETTINGS' | translate}}</span></li>
  <li *ngFor="let item of userDetails?.organizations" class="d-flex align-items-center height-31px" (click)="selectOrganization(item?.organization)"><span class="text-truncate" [class.active]="selectOrganizationModel.organizationId === item?.organization.id">{{item?.organization?.value}}</span></li>
  <li class="d-flex align-items-center" (click)="logoutFunc()">
    <svg style="stroke: none;" xmlns="http://www.w3.org/2000/svg" width="20.003" height="20.003" viewBox="0 0 20.003 20.003">
    <g id="logout_1_" data-name="logout (1)" transform="translate(0 -0.004)">
      <path id="Path_35133" data-name="Path 35133" d="M12.5,10.838a.833.833,0,0,0-.833.833v3.334a.834.834,0,0,1-.834.833h-2.5V3.338A1.68,1.68,0,0,0,7.2,1.753l-.247-.083h3.882a.834.834,0,0,1,.834.834V5a.833.833,0,0,0,1.667,0V2.5a2.5,2.5,0,0,0-2.5-2.5H1.875a.655.655,0,0,0-.089.018C1.746.019,1.708,0,1.667,0A1.668,1.668,0,0,0,0,1.671v15a1.68,1.68,0,0,0,1.135,1.584l5.016,1.672a1.725,1.725,0,0,0,.517.078,1.668,1.668,0,0,0,1.667-1.667v-.833h2.5a2.5,2.5,0,0,0,2.5-2.5V11.672a.833.833,0,0,0-.833-.833Zm0,0" transform="translate(0)" fill="#e0532f"/>
      <path id="Path_35134" data-name="Path 35134" d="M286.256,110.244l-3.334-3.334a.833.833,0,0,0-1.423.589V110h-3.334a.833.833,0,1,0,0,1.667H281.5v2.5a.833.833,0,0,0,1.423.589l3.334-3.334a.833.833,0,0,0,0-1.178Zm0,0" transform="translate(-266.497 -102.495)" fill="#e0532f"/>
    </g>
  </svg>
  <span>{{'HEADER.MYACCOUNT.LOGOUT' | translate}}</span></li>
</ul>