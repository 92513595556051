<button class="d-flex align-items-center justify-content-center common-button btn text-nowrap"
    id="{{id}}"
    name="{{name}}"
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass"
    (click)="buttonOutputEvent.emit()">
    {{buttonLabel | translate}}
    <!-- <i class="fa-solid fa fa-xmark"></i> -->
    <div *ngIf="isCrossIcon" class="ps-2 text-end">
        <svg (click)="crossButtonOutputEvent.emit()" id="close_1_" xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 10 10">
            <path id="Path_35127" data-name="Path 35127" d="M.947,12.443a.939.939,0,0,1-.672-.23.967.967,0,0,1,0-1.351L8.308,2.729a.938.938,0,0,1,1.337.045.967.967,0,0,1,.044,1.258L1.61,12.213a.939.939,0,0,1-.662.23Z" transform="translate(-0.001 -2.449)" fill="#fff"/>
            <path id="Path_35128" data-name="Path 35128" d="M10.455,11.839a.941.941,0,0,1-.663-.278L1.756,3.426a.966.966,0,0,1,.1-1.351.938.938,0,0,1,1.231,0l8.084,8.135a.967.967,0,0,1,.044,1.354l-.044.045a.939.939,0,0,1-.719.23Z" transform="translate(-1.474 -1.844)" fill="#fff"/>
          </svg>          
    </div>
    <div *ngIf="isClearIcon" class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16.5" viewBox="0 0 18 20">
            <g id="reset-undo" transform="translate(-4 -2)">
              <g id="Group_41566" data-name="Group 41566" transform="translate(4 2)">
                <path id="Path_35107" data-name="Path 35107" d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z" transform="translate(-4 -2)" fill="#fff"/>
              </g>
              <g id="Group_41567" data-name="Group 41567" transform="translate(4.231 2.95)">
                <path id="Path_35108" data-name="Path 35108" d="M12.507,22A9.3,9.3,0,0,1,9.1,21.367a10.057,10.057,0,0,1-4.687-3.694,1.032,1.032,0,0,1,.32-1.478,1.053,1.053,0,0,1,1.491.317,7.438,7.438,0,0,0,3.622,2.85,7.873,7.873,0,0,0,4.687.211,7.428,7.428,0,0,0,3.835-2.639,7.972,7.972,0,0,0,1.6-4.327A7.31,7.31,0,0,0,18.58,8.174,8.015,8.015,0,0,0,14.851,5.43a7.135,7.135,0,0,0-4.687,0A8.015,8.015,0,0,0,6.435,8.174a1.053,1.053,0,0,1-1.491.317.951.951,0,0,1-.213-1.478A9.495,9.495,0,0,1,9.524,3.425a9.6,9.6,0,0,1,5.966,0,9.495,9.495,0,0,1,4.794,3.588,9.017,9.017,0,0,1,1.7,5.7,9.017,9.017,0,0,1-2.024,5.594,10.319,10.319,0,0,1-5.007,3.377,8.615,8.615,0,0,1-2.45.317Z" transform="translate(-4.231 -2.95)" fill="#fff"/>
              </g>
            </g>
          </svg>
    </div>
</button>
