export class CreateEditRequest {
}

export enum RequestCategory{
    LinguisticMatchCall = 2,
    InterpretationRequest = 1,
}
export enum ScheduleOptions{
    OnDemand = 1,
    PreScheduled = 2

}
export enum CommunicationType{
    PhoneCall = "Phone Call",
    InPerson = "In-Person",
    VideoCall = "Video Call",
    EsPhoneCall = "Llamada telefónica",
    EsInPerson = "En persona",
    EsVideoCall = "Videollamada",
}

export enum ShowFormView{
    FormStart = 1,
    FormMiddle = 2,
    FormLast = 3,
    FormUpdate = 4
}

export class CreateRequestModel {
    id:number=0;
    scheduleOptionsId: number|number=0;
    appointmentCategoryId: number;
    callInNumber?: string|null=null;
    phoneNumber: string|null=null;
    phoneCodeId: string|number|null=null;
    link: string|null=null;
    serviceId: number|null=null;
    interpreterGenderRequestId: number;
    communicationTypeId: number|null=null;
    userId: string|null=null;
    organizationId: string|null=null;
    accessCode: string|null=null;
    // nameOfLocation: string|null=null;
    appointmentLangTypeId:number;
    notes: any[];
    interpretationLanguages:any[];
    location = new Location();
    timeDuration = new TimeDuration();
    matchCallLanguages = new MatchCallLanguage();
}
export class CompleteAppoinmentModel {
  appointmentId:number|null=null;
  appointmentCompletionStateTypeId:string|null=null;
  actualEndTime:string|null=null;
  reasonsForEndedEarly:any[];
  interpreterNote :string|null=null;
}

  

  export class MatchCallLanguage{
    id?:number|null;
    language: string;
    langVariants: any[];
  }
  
  export class TimeDuration {
    date: string='';
    startTime: string;
    endTime: string;
  }

  export class NotesType{
    id:number|null=null;
    noteTypeId: number;
    body: string;
  }
  
  export class AppointmentAcceptableLanguage {
    id:number|null=null;
    entryState:number=0;
    interpretationLangTypeId: number = 2;
    fromLangVariantId: number;
    toLangVariantId: number;
  }

  export class AppointmentPreferredLanguage {
    id:number|null=null;
    entryState:number=0
    interpretationLangTypeId: number = 1;
    fromLangVariantId: number;
    toLangVariantId: number;
  }
  
  export class Location {
    nameOfLocation : string|null=null;
    addressLine1: string|null=null;
    addressLine2: string|null=null
    landmark: string|null=null
    city: string|null=null
    zipCode: string|null=null
    stateId: number|null=null;
  }

  export class checkbox{
    checkbox1:boolean;
    checkbox2:boolean;
    checkbox3:boolean;

  }

  export enum NoteType{
      InternalNote = 1,
      InterpreterNote = 2,
      RequesterNote = 3
  }

  export class InternalNotes{
    id:number;
    entryState:number;
    internalDesc:string;
  }

  export class ReqursterNotes{
    id:number;
    entryState:number;
    reqursterDesc:string;
  }

  export class InterpreterNotes{
    id:number;
    entryState:number;
    InterpreterDesc:string;
  }