import { ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AssignInterpretersService } from './assign-interpreters.service';
import { AssignModal, AssignationProcessType, GetInterpreterModel, NewGetInterpreterModel, SelectedInterpreterModel } from './assign-interpreters.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestCategory, ScheduleOptions } from '../create-edit-request/create-edit-request.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { DropDownModel } from 'src/app/components/AdminSettings/user-management/tabs-component/user-list/user-list.model';
import { RequestListService } from '../../request-list.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { FormSettingTitle, GetLookupFormEnum } from 'src/app/shared/enum/common-enum';
import { NgForm } from '@angular/forms';
import { FormSettingsService } from 'src/app/components/AdminSettings/form-settings/form-settings.service';

@Component({
  selector: 'app-assign-interpreters',
  templateUrl: './assign-interpreters.component.html',
  styleUrls: ['./assign-interpreters.component.scss']
})
export class AssignInterpretersComponent extends CommonService implements OnInit {

  getRequestData:any;
  requestCategory = RequestCategory;
  getInterpreterModel = new GetInterpreterModel();
  newGetInterpreterModel = new NewGetInterpreterModel();

  interpreterList :DropDownModel[] = [];
  langWithInterpreterList:any[]=[];
  selectedInterpreterModel= new SelectedInterpreterModel();
  assignModal = new AssignModal();
  selectedinterpreterId: number[]=[];
  @ViewChild("AssignForm") AssignForm!: NgForm;
  scheduleOptions = ScheduleOptions;
  @Output() reqAssigned = new EventEmitter;
  assignationProcessType = AssignationProcessType;

  getLookupFormEnum = GetLookupFormEnum;
  formSettingTitle = FormSettingTitle;
  languagesListData: any;
  seletedlanguage: any;
  copySeletedlanguage: any;
  requestAssigned: any;



  constructor(
    public activeModal: NgbActiveModal,
    private assignInterpretersService: AssignInterpretersService,
    public toaster:ToastrService,
    public requestListService: RequestListService,
    public formSettingsService: FormSettingsService,
    public cdrf:ChangeDetectorRef,
    private storageService:StorageService){
    super();
  }

  ngOnInit() {
    this.getRequestedData();
  }

  getRequestedData() {
    if (this.getRequestData?.scheduleOptionsId == this.scheduleOptions.OnDemand) {
      this.assignModal.assignationProcessTypeId = this.assignationProcessType.Automatic;
    }
    else if (this.getRequestData?.scheduleOptionsId == this.scheduleOptions.PreScheduled) {
      this.assignModal.assignationProcessTypeId = this.assignationProcessType.Manual;
    }
    this.getInterpreterModel.genderId = this.getRequestData?.interpreterGenderRequest?.id ?? null;
    this.getInterpreterModel.timeDuration.date = this.getRequestData?.date;
    this.getInterpreterModel.timeDuration.startTime = this.getRequestData?.startTime;
    this.getInterpreterModel.timeDuration.endTime = this.getRequestData?.endTime;

    // for updatedModel to get interpreterList
    this.newGetInterpreterModel.appointmentId = this.getRequestData?.id;
    this.newGetInterpreterModel.isUnavailableInterpretersIncluded = this.getInterpreterModel.isUnavailableInterpretersIncluded;
    // for updatedModel to get interpreterList
    if(this.getRequestData?.appointmentCategoryId == this.requestCategory?.LinguisticMatchCall){
    this.formSettingsService.getAllFormSettings(
      this.getLookupFormEnum.LanguageAndVariant, undefined, undefined
    )
      .subscribe((res: ResponseModel) => {
        if (res.isSucceeded === true) {
          this.languagesListData = res.response.lookupTables.filter(
            (item) =>
              item.value ==
              this.formSettingTitle.languagesAndVariants
          )[0].lookupValues;
        }
        else{
          if (res.message != undefined) {
            this.toaster.error(res.message);
        }
        if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0]);
        }
        }
      });
    } else {
      this.getInterpreterlist();
    }
  }

  getInterpreterlist(){
    this.newGetInterpreterModel.isAcceptableLanguage = this.assignModal.isAcceptableLanguage;
    // if(this.getInterpreterModel.langVariantId){
      this.newGetInterpreterModel.isUnavailableInterpretersIncluded =this.getInterpreterModel.isUnavailableInterpretersIncluded ;
    // }
    this.assignInterpretersService.getInterpreterList(this.newGetInterpreterModel)
    .subscribe((res: ResponseModel) => {
      if (res.isSucceeded) {
        if(!res.response??[]){
          this.interpreterList = res.response??[];
          this.toaster.success(res.message);
        }else{
          this.interpreterList = res.response??[];
        }
      }
      else{
        if (res.message != undefined) {
          this.interpreterList = [];
          this.toaster.error(res.message);
      }
      if (res?.errors?.length > 0) {
          this.interpreterList = [];
          this.toaster.error(res.errors[0]);
      }
      }
    });
  }

  // InternalAssign api call
  
  InternalAssignappointment(data){
    if(this.AssignForm.valid){
      this.assignModal.appointmentId = data.id;
      switch (data.appointmentCategoryId) {
        case this.requestCategory.LinguisticMatchCall:
          this.assignModal.interpretersUserId = this.langWithInterpreterList?.map(a=>a.id);
          this.assignModal.assignationProcessTypeId = this.assignationProcessType.Manual;
          break;
        case this.requestCategory.InterpretationRequest:
          if (this.assignModal.assignationProcessTypeId == this.assignationProcessType.Automatic) {
            this.assignModal.interpretersUserId = null;
          } else {
            this.assignModal.interpretersUserId = this.assignModal.interpretersUserId;
          }
          break;
        default:
          break;
      }
      this.requestListService.InternalAssign(this.assignModal).subscribe((res)=>{
          if (res.isSucceeded) {
            console.log(res);
            this.reqAssigned.emit(true);
            this.toaster.success(res.message);
            this.selectedInterpreterModel = new SelectedInterpreterModel();
            this.seletedlanguage =  undefined;
            this.assignModal.interpretersUserId = [];
            // if(this.assignModal.assignationProcessTypeId == this.assignationProcessType.Automatic){
            //   this.activeModal.close(true);
            // }
            this.activeModal.close(true);
          }
          else{
            if (res.message != undefined) {
              this.toaster.error(res.message);
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0]);
          }
          }
      });
    }
  }


  addNewLanguages(event) {
        var langugagePushModel = {
            id:
                event?.languageVariant != undefined
                    ? event?.languageVariant?.id
                    : event?.language?.languageVariant[0]?.id,
            value:  event?.language?.value
        };
        this.seletedlanguage  = {language:event?.language,languageVariant:event?.languageVariant};
        this.copySeletedlanguage = Object.assign(langugagePushModel);
        this.getInterpreterModel.langVariantId = event?.language?.languageVariant[0].id;
        this.newGetInterpreterModel.languageVariantId = event?.languageVariant?.id;
        this.getInterpreterlist();
}

  addInterpreter() {
    if(!this.langWithInterpreterList?.map(a=>a.id).includes(this.selectedInterpreterModel?.interpreterId))
      {   
      let selInterpreter: any[] = this.interpreterList.filter(x => x.id == this.selectedInterpreterModel?.interpreterId);
      if (!this.langWithInterpreterList) {
        this.langWithInterpreterList = [];
      };
      let Obj: any = {
        name: this.copySeletedlanguage?.value+'-'+selInterpreter[0]?.value,
        id: this.selectedInterpreterModel?.interpreterId
        // name: this.copySeletedlanguage?.value+'-'+selInterpreter[0]?.name, // for disabled language option
        // interpreterId: this.selectedInterpreterModel?.interpreterId, // for disabled language option
        // id:this.copySeletedlanguage?.id, // for disabled language option
        // entryState: 1, // for disabled language option
      };
      if(this.selectedInterpreterModel?.interpreterId != undefined){
        this.langWithInterpreterList?.push(Obj);
      }
      }
      else{
        this.toaster.error("This interpreter is already selected.")
      }
  }

  remove(index){
    if (index >= 0 && index < this.langWithInterpreterList.length) {
      this.langWithInterpreterList?.splice(index, 1);
  }
}

}